<template>
  <div id="app">
    <Header msg="Header"/>
    <router-view />
    <Footer msg="Footer"/>
  </div>
</template>

<script>
import Header from '@/views/layout/Header.vue';
import Footer from '@/views/layout/Footer.vue';

export default{
  name: 'App',
  metaInfo: {
    title: 'Instituto de Mexicanas y Mexicanos en el Exterior',
    titleTemplate: '%s | IME'
  },
  components:{
    Header,
    Footer,
  },
}
</script>