<template>
 
  <div class="attention-ime-two-columns">
    <b-container class="mt-5 mb-5">
      <b-row class="">
        <b-col cols="12" class="">
          <h1><i class="fas fa-hands-helping"></i> {{title}} </h1>
          <hr class="line sm">
        </b-col>
      </b-row>
      <b-row>
        <b-col
          class="mb-4 "
           lg="3" md="3" sm="12" xs="12" 
          v-for="category in categories" 
          :key="category.id"
          
        >
          <div
          class="attention-card z-depth-3 box-15 " 
          :style="`background-image: url(${ path.storage_files + category.image_cover.formats.medium.url })`"
        > 
        <!-- <div
          class="attention-card z-depth-3 box-15 " 
          :style="`<span class=${category.icono} aria-hidden=${true}></span>`"
        > -->

            <div class="gradiente-text box-15">
              <div class="white-text p-3">
                <h3 class="text-shadow">
                  <b-link v-if="!category.url_redirect" class="white-text" :to="`${ category.slug }`">
                    {{ category.name }}
                  </b-link>
                  <b-link v-else class="white-text" :href="`${ category.url_redirect }`" target="_blank">
                    {{ category.name }}
                  </b-link>
                </h3>
                <!--<p class="lead text-shadow">{{ category.description }}</p>-->
              </div>
            </div>
         </div>
        </b-col>
      </b-row>

    </b-container>
  </div>
</template>

<script>
import DataCategories from "@/services/data-categories";
import FlipCard from 'vue-info-card';

export default {
  name: "LastPostsEight",
  components: {
    DataCategories,
    FlipCard,
  },

  props: {
    title: String,
  },

  data() {
    return {
      categories: [],
      path: {
        base_url: process.env.VUE_APP_BASE_URL,
        endpoint: process.env.VUE_APP_ENDPOINT,
        storage_files: process.env.VUE_APP_STORAGE_FILES,
      },
    }
  },

  mounted() {
    this.retrieveCategories();
  },

  methods: {
    retrieveCategories() {
      DataCategories.getAllReorder()
      .then(response => {
        this.categories = response.data;
        console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
    },
  },

}
</script>
