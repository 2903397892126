<template>
  <div :class="`categories category-${cfg.category}`">
    <div v-for="setting in settingCategory" :key="setting.id">
      <SeoComponent
        :seoTitle="`${setting.category.name ? setting.category.name : 'IME'}`"
        :seoDescription="
          `${
            setting.seo.description
              ? setting.seo.description
              : 'Instituto de los Mexicanos en el Exterior'
          }`
        "
        :seoTags="`${setting.seo.tags ? setting.seo.tags : 'gobmx,sre,ime'}`"
        :seoCanonical="`${setting.category.slug ? setting.category.slug : '/'}`"
        :seoImage="
          `${
            setting.category.image_cover
              ? path.storage_files + setting.category.image_cover.url
              : '/images/categorias/vaipoime.jpg'
          }`
        "
      />

      <HeaderCategory
        v-if="setting.Header.static_image === true"
        :nameCategory="
          `${setting.category.name ? setting.category.name : cfg.nameCategory}`
        "
        :imageCategory="
          `${
            setting.category.image_cover
              ? path.storage_files + setting.category.image_cover.url
              : '/images/categorias/vaipoime.jpg'
          }`
        "
        :description=" `${setting.category.description && setting.category.description }`"
      />

      <HeaderCategorySlide
        v-if="setting.Header.slide_image === true"
        :slugCategory="
          `${setting.category.slug ? setting.category.slug : cfg.category}`
        "
        :nameCategory="
          `${setting.category.name ? setting.category.name : cfg.nameCategory}`
        "
        :imageCategory="
          `${
            setting.category.image_cover
              ? path.storage_files + setting.category.image_cover.url
              : '/images/categorias/vaipoime.jpg'
          }`
        "
      />

      <PrimaryTwoSecondariesCategory
        v-if="setting.Outstanding.primary_two_secondaries === true"
        :cfgTitle="
          `${
            setting.Outstanding.title ? setting.Outstanding.title : 'Destacados'
          }`
        "
        :cfgCategory="
          `${setting.category.slug ? setting.category.slug : cfg.category}`
        "
      />

      <MainBanner />

      <ProgramsFourColumns
        v-if="setting.ActionsPrograms.showPrograms === true"
        :cfgTitle="
          `${
            setting.ActionsPrograms.title
              ? setting.ActionsPrograms.title
              : 'Acciones y Programas'
          }`
        "
        :cfgCategory="
          `${setting.category.slug ? setting.category.slug : cfg.category}`
        "
        :cfgBgColor="`${setting.ActionsPrograms.material_bg_color_palette}`"
        :cfgLinkColor="`${setting.ActionsPrograms.material_color_palette}`"
      />

      <Video
        v-if="setting.OutstandingVideo.showVideo === true"
        :videoTitle="`${setting.category.video.title}`"
        :videoId="`${setting.category.video.youtube_id}`"
        :videoDescription="`${setting.category.video.description}`"
        :videoMaterialColor="
          `${setting.OutstandingVideo.material_color_palette}`
        "
        :videoBgColor="`${setting.OutstandingVideo.material_bg_color_palette}`"
      />

      <LastPostsThreeByCategory
        v-if="setting.LastPosts.last_posts_three === true"
        :cfgTitle="
          `${setting.LastPosts.title ? setting.LastPosts.title : 'Blog'}`
        "
        :cfgCategory="
          `${setting.category.slug ? setting.category.slug : cfg.category}`
        "
        :cfgLimitPost="
          `${setting.LastPosts.limit_post ? setting.LastPosts.limit_post : 3}`
        "
      />

      <SingleBanner
        v-if="setting.SingleBanner.showBanners === true"
        :cfgTitle="
          `${
            setting.SingleBanner.title ? setting.SingleBanner.title : 'Anuncios'
          }`
        "
        :cfgCategory="
          `${setting.category.slug ? setting.category.slug : cfg.category}`
        "
      />
    </div>
  </div>
</template>

<script>
import SeoComponent from "@/components/Global/Seo.vue";
import HeaderCategory from "@/components/Custom/Layout/HeaderCategory.vue";
import HeaderCategorySlide from "@/components/Custom/Layout/HeaderCategorySlide.vue";
import Video from "@/components/Custom/Layout/Video.vue";
import PrimaryTwoSecondariesCategory from "@/components/Custom/Outstanding/PrimaryTwoSecondariesCategory.vue";
import MainBanner from "@/components/Global/MainBanner.vue";
import LastPostsThreeByCategory from "@/components/Custom/LatestPosts/LastPostsThreeByCategory.vue";
import SingleBanner from "@/components/Custom/Banners/SingleBanner.vue";
import ProgramsFourColumns from "@/components/Custom/Programs/FourColumns.vue";

export default {
  name: "ShowCategories",
  components: {
    SeoComponent,
    HeaderCategory,
    HeaderCategorySlide,
    Video,
    PrimaryTwoSecondariesCategory,
    MainBanner,
    LastPostsThreeByCategory,
    SingleBanner,
    ProgramsFourColumns,
  },

  data() {
    return {
      settingCategory: [],
      path: {
        base_url: process.env.VUE_APP_BASE_URL,
        endpoint: process.env.VUE_APP_ENDPOINT,
        storage_files: process.env.VUE_APP_STORAGE_FILES,
      },
      cfg: {
        showMenu: this.$route.meta.cfgShowMenu,
        category: this.$route.meta.cfgCategory,
        nameCategory: this.$route.meta.cfgNameCategory,
      },
    };
  },

  mounted() {},

  /*
   * Configuracion Inicial
   */
  async created() {
    await fetch(
      this.path.endpoint +
        "/landings/?category.slug=" +
        this.cfg.category +
        "&isActive=true" // <- Ajuste segun Categoria
    )
      .then((response) => response.json())
      .then((data) => {
        this.settingCategory = data;
      })
      .catch((err) => {
        console.error(err);
      });
  },
  /* End Ajuste */

  methods: {},
};
</script>
