<template>
  <div class="component-header-top-menu">
    <section class="header-top-menu  white-text light">
      <b-container>
        <b-row>
          <b-col class=""> </b-col>
          <b-col col lg="4" md="4" sm="12" class="mt-1 text-right">
            <b-button
              size="sm"
              variant="link"
              href="https://www.gob.mx/tramites"
              style="text-decoration: none;"
              class="strong"
            >
              Trámites
            </b-button>
            <b-button
              size="sm"
              variant="link"
              href="https://www.gob.mx/gobierno"
              style="text-decoration: none;"
              class="strong"
            >
            Gobierno
            </b-button>
            <b-button
              size="sm"
              variant="link"
              href="mailto:consultasime@sre.gob.mx"
              style="text-decoration: none;"
              class="strong"
            >
              <i class="fas fa-envelope" aria-hidden="true"></i>
              Buzón IMME
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </div>
</template>

<script>
import ScrollComponent from "@/components/Custom/News/Scroll.vue";

export default {
  name: "HeaderTopMenu",

  components: {
    ScrollComponent,
  },
  props: {
    msg: String,
  },

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>
