<template>
  <div class="component-header-navbar">
    <b-navbar
      sticky
      type="dark"
      variant=""
      fixed
      class="dorado-obscuro shadow d-none d-sm-none d-md-none d-lg-block"
    >
      <b-container>
        <b-navbar-nav>
          <b-nav-item to="/" class="logo-content">
            <b-img
              alt="Instituto de los Mexicanos en el Exterior"
              src="/images/ime-mx.png"
              class="logo"
            ></b-img>
          </b-nav-item>
        </b-navbar-nav>

        <b-navbar-nav class="ml-auto">
          <!--<b-nav-form>
            <b-form-input size="sm" class="mr-sm-2" placeholder="Search"></b-form-input>
            <b-button size="sm" class="my-2 my-sm-0" type="submit">Search</b-button>
          </b-nav-form>-->

          <b-nav-item to="/">Inicio</b-nav-item>

          <b-nav-item
            v-for="start in startPrincipalMenu"
            :key="start.id"
            :href="`${start.url_redirect ? start.url_redirect : start.slug}`"
          >
            {{ start.name }}
          </b-nav-item>

          <b-nav-item-dropdown right class="">
            <template #button-content>
              Comunidades
            </template>
            <b-dropdown-item
              v-for="community in communitiesMenu"
              :key="community.id"
              :href="`/${community.slug}`"
            >
              {{ community.name }}
            </b-dropdown-item>
          </b-nav-item-dropdown>

          <b-nav-item-dropdown
            right
            v-if="$route.meta.cfgShowMenu === true"
            class="category-menu box-5"
          >
            <template #button-content>
              <span class="strong white-text">
                {{ $route.meta.cfgNameCategory }}
              </span>
            </template>
            <b-dropdown-item :to="`/${$route.meta.cfgCategory}/`">
              Inicio
            </b-dropdown-item>
            <b-dropdown-item :to="`/${$route.meta.cfgCategory}/articulos`">
              Artículos
            </b-dropdown-item>
            <b-dropdown-item :to="`/${$route.meta.cfgCategory}/programas`">
              Programas
            </b-dropdown-item>
            <b-dropdown-item :to="`/${$route.meta.cfgCategory}/eventos`">
              Eventos
            </b-dropdown-item>
          </b-nav-item-dropdown>

          <b-nav-item
            v-for="end in endPrincipalMenu"
            :key="end.id"
            :href="`${end.url_redirect ? end.url_redirect : end.slug}`"
          >
            {{ end.name }}
          </b-nav-item>
        </b-navbar-nav>
      </b-container>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: "HeaderNavbar",
  components: {},
  props: {
    msg: String,
  },

  data() {
    return {
      error: null,
      errors: [],
      startPrincipalMenu: [],
      endPrincipalMenu: [],
      communitiesMenu: [],
      path: {
        base_url: process.env.VUE_APP_BASE_URL,
        endpoint: process.env.VUE_APP_ENDPOINT,
        storage_files: process.env.VUE_APP_STORAGE_FILES,
      },
      cfg: {
        showMenu: this.$route.meta.cfgShowMenu,
        category: this.$route.meta.cfgCategory,
        nameCategory: this.$route.meta.cfgNameCategory,
      },
    };
  },

  computed: {
    category() {
      return this.$route.name;
    },
  },

  mounted() {},

  /*
   * Configuracion Inicial
   */
  async created() {
    await fetch(this.path.endpoint + "/categories/?menu=start_principal")
      .then((response) => response.json())
      .then((data) => {
        this.startPrincipalMenu = data;
      })
      .catch((err) => {
        console.error(err);
      });

    await fetch(this.path.endpoint + "/categories/?menu=end_principal")
      .then((response) => response.json())
      .then((data) => {
        this.endPrincipalMenu = data;
      })
      .catch((err) => {
        console.error(err);
      });

    await fetch(this.path.endpoint + "/categories/?menu=communities")
      .then((response) => response.json())
      .then((data) => {
        this.communitiesMenu = data;
      })
      .catch((err) => {
        console.error(err);
      });
  },
  /* End Ajuste */

  methods: {},
};
</script>
