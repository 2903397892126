var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:("categories category-" + (_vm.cfg.category))},_vm._l((_vm.settingCategory),function(setting){return _c('div',{key:setting.id},[_c('SeoComponent',{attrs:{"seoTitle":("" + (setting.category.name ? setting.category.name : 'IME')),"seoDescription":("" + (setting.seo.description
            ? setting.seo.description
            : 'Instituto de los Mexicanos en el Exterior')),"seoTags":("" + (setting.seo.tags ? setting.seo.tags : 'gobmx,sre,ime')),"seoCanonical":("" + (setting.category.slug ? setting.category.slug : '/')),"seoImage":("" + (setting.category.image_cover
            ? _vm.path.storage_files + setting.category.image_cover.url
            : '/images/categorias/vaipoime.jpg'))}}),(setting.Header.static_image === true)?_c('HeaderCategory',{attrs:{"nameCategory":("" + (setting.category.name ? setting.category.name : _vm.cfg.nameCategory)),"imageCategory":("" + (setting.category.image_cover
            ? _vm.path.storage_files + setting.category.image_cover.url
            : '/images/categorias/vaipoime.jpg')),"description":("" + (setting.category.description && setting.category.description))}}):_vm._e(),(setting.Header.slide_image === true)?_c('HeaderCategorySlide',{attrs:{"slugCategory":("" + (setting.category.slug ? setting.category.slug : _vm.cfg.category)),"nameCategory":("" + (setting.category.name ? setting.category.name : _vm.cfg.nameCategory)),"imageCategory":("" + (setting.category.image_cover
            ? _vm.path.storage_files + setting.category.image_cover.url
            : '/images/categorias/vaipoime.jpg'))}}):_vm._e(),(setting.Outstanding.primary_two_secondaries === true)?_c('PrimaryTwoSecondariesCategory',{attrs:{"cfgTitle":("" + (setting.Outstanding.title ? setting.Outstanding.title : 'Destacados')),"cfgCategory":("" + (setting.category.slug ? setting.category.slug : _vm.cfg.category))}}):_vm._e(),_c('MainBanner'),(setting.ActionsPrograms.showPrograms === true)?_c('ProgramsFourColumns',{attrs:{"cfgTitle":("" + (setting.ActionsPrograms.title
            ? setting.ActionsPrograms.title
            : 'Acciones y Programas')),"cfgCategory":("" + (setting.category.slug ? setting.category.slug : _vm.cfg.category)),"cfgBgColor":("" + (setting.ActionsPrograms.material_bg_color_palette)),"cfgLinkColor":("" + (setting.ActionsPrograms.material_color_palette))}}):_vm._e(),(setting.OutstandingVideo.showVideo === true)?_c('Video',{attrs:{"videoTitle":("" + (setting.category.video.title)),"videoId":("" + (setting.category.video.youtube_id)),"videoDescription":("" + (setting.category.video.description)),"videoMaterialColor":("" + (setting.OutstandingVideo.material_color_palette)),"videoBgColor":("" + (setting.OutstandingVideo.material_bg_color_palette))}}):_vm._e(),(setting.LastPosts.last_posts_three === true)?_c('LastPostsThreeByCategory',{attrs:{"cfgTitle":("" + (setting.LastPosts.title ? setting.LastPosts.title : 'Blog')),"cfgCategory":("" + (setting.category.slug ? setting.category.slug : _vm.cfg.category)),"cfgLimitPost":("" + (setting.LastPosts.limit_post ? setting.LastPosts.limit_post : 3))}}):_vm._e(),(setting.SingleBanner.showBanners === true)?_c('SingleBanner',{attrs:{"cfgTitle":("" + (setting.SingleBanner.title ? setting.SingleBanner.title : 'Anuncios')),"cfgCategory":("" + (setting.category.slug ? setting.category.slug : _vm.cfg.category))}}):_vm._e()],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }